import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { WebsiteLink } from "../components/Link";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Information = makeShortcode("Information");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "methods"
    }}>{`Methods`}</h1>
    <h2 {...{
      "id": "getsessionurl"
    }}>{`getSessionURL`}</h2>
    <p>{`Get URL to current session.`}</p>
    <h3 {...{
      "id": "__lsgetsessionurl-callback"
    }}>{`_`}{`_`}{`ls("getSessionURL", callback)`}</h3>
    <Information color="info" mdxType="Information">
  This method works asynchronusly. <b>callback</b> fires after session is inited.
    </Information>
    <h4 {...{
      "id": "return"
    }}>{`Return`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`https://app.livesession.io/app/session/{visitor_id}/{session_id}`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isNewSession`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "example"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("getSessionURL", function(url, isNewSession) {
  // do only if it's a new session
  if (isNewSession) {
    YOUR_API.addSessionURL(url);
  }
});
`}</code></pre>
    <p>{`Integration with other software:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("getSessionURL", function(url, isNewSession) {
  if (isNewSession) drift.track("LiveSession recording URL", { sessionURL: url });
});
`}</code></pre>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "init"
    }}>{`init`}</h2>
    <p>{`Init LiveSession tracking stript. You can find your website tracking ID in `}<strong parentName="p">{`LiveSession -> Settings -> Websites`}</strong>{`.`}</p>
    <h3 {...{
      "id": "__lsinit-trackingid-options"
    }}>{`_`}{`_`}{`ls("init", trackingID, options)`}</h3>
    <h4 {...{
      "id": "options"
    }}>{`Options`}</h4>
    <p>{`See `}<a parentName="p" {...{
        "href": "/javascript-api/configuration/"
      }}>{`configuration section`}</a></p>
    <h4 {...{
      "id": "example-1"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("init", "abc.cdef");
`}</code></pre>
    <p>{`With options:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("init", "abc.cdef", { keystrokes: true });
`}</code></pre>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "identify"
    }}>{`identify`}</h2>
    <p>{`Identify user and custom data to session.`}</p>
    <h3 {...{
      "id": "__lsidentify-data"
    }}>{`_`}{`_`}{`ls("identify", data)`}</h3>
    <h4 {...{
      "id": "validation"
    }}>{`Validation`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Maximum length`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Details`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Displays user names in app`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Displays user email`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`params`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 items`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{key:value}`}</inlineCode>{` JSON object`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "example-2"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("identify", { name: "John Doe", email: "john.doe@example.com" });
`}</code></pre>
    <p>{`With params:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("identify", {
  name: "John Doe",
  email: "john.doe@example.com",
  params: {
    order_id: "123-abc-def",
    plan: "premium",
  },
});
`}</code></pre>
    <Information color="warning" mdxType="Information">Users' name and email will be the same across all of their sessions.</Information>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "track"
    }}>{`track`}</h2>
    <p>{`Track method allows you to track custom actions that your users perform along with custom properties.`}</p>
    <h3 {...{
      "id": "__lstrack-event-properties"
    }}>{`_`}{`_`}{`ls("track", event, properties)`}</h3>
    <Information color="warning" mdxType="Information">
  <b>Important:</b> Currently event properties are only visible in <WebsiteLink blank href={'/help/how-does-the-timeline-work'} mdxType="WebsiteLink">timeline</WebsiteLink>.
  Next version will allow you to find properties through <WebsiteLink blank href={'help/how-does-the-search-work'} mdxType="WebsiteLink">search</WebsiteLink> filter terms.
    </Information>
    <h4 {...{
      "id": "validation-1"
    }}>{`Validation`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Maximum length`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Details`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`256`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Displays event name in your system`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`properties`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`object`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 items`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{key:value}`}</inlineCode>{` JSON object`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`properties`}{`[key]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`256`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Properties key`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "properties-value-validation"
    }}>{`Properties value validation`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Maximum length`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Details`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`{name}_str`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`256`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String property value, eg. `}<inlineCode parentName="td">{`{plan_str: "premium"}`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`{name}_int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`int`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`int max. value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Int property value, eg. `}<inlineCode parentName="td">{`{seats_int: 2}`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`{name}_float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`float`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float max. value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Float property value, eg. `}<inlineCode parentName="td">{`{total_float: 255.50}`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`{name}_bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bool property value, eg. `}<inlineCode parentName="td">{`{isPatron_bool: true}`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <Information color="warning" mdxType="Information">Nested properties are not allowed.</Information>
    <h4 {...{
      "id": "example-3"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("track", "User Subscribed");
`}</code></pre>
    <p>{`With properties:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("track", "User Subscribed", {
  plan_str: "premium",
  seats_int: 1,
  total_float: 255.50,
  isPatron_bool: true
});
`}</code></pre>
    <p>{`You can use properties without type suffix in the property key (this variant is not recommended).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("track", "User Subscribed", {
  plan: "premium",
  seats: 1,
  total: 255.50,
  isPatron: true
});
`}</code></pre>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "invalidatesession"
    }}>{`invalidateSession`}</h2>
    <p>{`Close curent session.`}</p>
    <h3 {...{
      "id": "__lsinvalidatesession"
    }}>{`_`}{`_`}{`ls("invalidateSession")`}</h3>
    <h4 {...{
      "id": "example-4"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("invalidateSession");
`}</code></pre>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "newpageview"
    }}>{`newPageView`}</h2>
    <p>{`Start recording user's visit and add it to session when conditions fulfilled. If session doesn't exists it also create new session.`}</p>
    <h3 {...{
      "id": "__lsnewpageview-options"
    }}>{`_`}{`_`}{`ls("newPageView", options)`}</h3>
    <h4 {...{
      "id": "options-1"
    }}>{`Options`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Overwrite page title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conditions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`array of objects`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Conditions for starting new `}<inlineCode parentName="td">{`pageView`}</inlineCode>{` (`}<a parentName="td" {...{
              "href": "/javascript-api/recording-conditions/"
            }}>{`learn more`}</a>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`baseURL`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` or `}<inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Overwrite base URL on player`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "example-5"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("newPageView");
`}</code></pre>
    <p>{`with options:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("newPageView", { title: "Anonimized page title" });
`}</code></pre>
    <p>{`with conditions (`}<a parentName="p" {...{
        "href": "/javascript-api/recording-conditions/"
      }}>{`learn more`}</a>{`):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("newPageView", {
  conditions: [{ type: "event", name: "MouseClick", operator: "contain", key: "path", value: ".add-cart" }],
});
`}</code></pre>
    <p>{`with base URL`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("newPageView", {
  baseURL: "https://example.com",
});

// or

__ls("newPageView", {
  baseURL: function(base) {
    return base
  },
});
`}</code></pre>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "setoptions"
    }}>{`setOptions`}</h2>
    <p>{`Set options and init LiveSession tracking stript (if it's not inited). You can find your website ID and account ID in `}<strong parentName="p">{`LiveSession -> Settings -> Websites`}</strong>{`.`}</p>
    <h3 {...{
      "id": "__lssetoptions-options"
    }}>{`_`}{`_`}{`ls("setOptions", options)`}</h3>
    <h4 {...{
      "id": "options-2"
    }}>{`Options`}</h4>
    <p>{`See `}<a parentName="p" {...{
        "href": "/javascript-api/configuration/"
      }}>{`configuration section`}</a></p>
    <h4 {...{
      "id": "example-6"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("setOptions", { accountID: "abc", websiteID: "cdef" });
`}</code></pre>
    <p>{`Set keystrokes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("setOptions", { keystrokes: true });
`}</code></pre>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "setcustomparams"
    }}>{`setCustomParams`}</h2>
    <p>{`Set custom properties to session.`}</p>
    <h3 {...{
      "id": "__lssetcustomparams-data"
    }}>{`_`}{`_`}{`ls("setCustomParams", data)`}</h3>
    <h4 {...{
      "id": "validation-2"
    }}>{`Validation`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Maximum length`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Details`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 items`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{key:value}`}</inlineCode>{` JSON object`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "example-7"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("setCustomParams", {
  params: {
    order_id: "123-abc-def",
    plan: "premium",
  },
});
`}</code></pre>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "off"
    }}>{`off`}</h2>
    <p>{`Turn LiveSession script off.`}</p>
    <h3 {...{
      "id": "__lsoff"
    }}>{`_`}{`_`}{`ls("off")`}</h3>
    <h4 {...{
      "id": "example-8"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("off");
`}</code></pre>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "optout"
    }}>{`optOut`}</h2>
    <p>{`Set optOut flag. This method sets a `}<inlineCode parentName="p">{`__ls_optout`}</inlineCode>{` cookie on current domain to inform LiveSession script to turn off.`}</p>
    <h3 {...{
      "id": "__lsoptout-value"
    }}>{`_`}{`_`}{`ls("optOut", value)`}</h3>
    <h4 {...{
      "id": "example-9"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("optOut", true);
`}</code></pre>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "debug"
    }}>{`debug`}</h2>
    <p>{`Set debug logging mode.`}</p>
    <h3 {...{
      "id": "__lsdebug-value"
    }}>{`_`}{`_`}{`ls("debug", value)`}</h3>
    <h4 {...{
      "id": "example-10"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("debug", true);
`}</code></pre>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "log"
    }}>{`log`}</h2>
    <p>{`Standard console.log() statements will be recorded by LiveSession, but you have the option to log messages without adding additional noise to your users browser consoles.`}</p>
    <h3 {...{
      "id": "__lslog-loglevel-data"
    }}>{`_`}{`_`}{`ls("log", logLevel?, data)`}</h3>
    <h4 {...{
      "id": "parameters"
    }}>{`Parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`logLevel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`These values are identical to the four methods available to the browser console API. If this parameter is not provided, the 'log' value is used by default`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A object that will be logged to the LiveSession console`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "loglevel-supported"
    }}>{`logLevel supported`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Level`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`"log"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object will be logged as log`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`"info"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object will be logged as info`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`"warn"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object will be logged as warn`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`"error"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object will be logged as error`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "example-11"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("log","info","demo info message");
__ls("log","warn","demo warn message");
__ls("log","error",{ id:2, message:"demo error message" });
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      